import { render, staticRenderFns } from "./editTariff.vue?vue&type=template&id=31abf0de&scoped=true&lang=pug"
import script from "./editTariff.vue?vue&type=script&lang=js"
export * from "./editTariff.vue?vue&type=script&lang=js"
import style0 from "./editTariff.vue?vue&type=style&index=0&id=31abf0de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31abf0de",
  null
  
)

export default component.exports