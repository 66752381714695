<template lang="pug">
.absolute.bottom-1.top-1.left-1.z-10.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module.h-full.w-full(
  style='grid-template-rows: auto auto auto auto 1fr'
)
  .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ $t("admintariff.changetitle") }}
  .flex.justify-end.col-start-2.items-center
    img.mr-5.cursor-pointer(
      src='@/assets/icons/history.svg',
      style='width: 18px',
      @click='showHistory'
    )
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .flex.w-full.justify-between.flex-col.mt-3.w-full.col-start-1.col-end-3
    .flex.justify-between.items-center.w-full.mt-3.py-2.px-4(style='background-color:#F6F7FA; border-radius:3px')
        .info-title {{ $t('editTarifTranslater.dateStart') }}
        .flex.flex-col.w-48
          .info-title {{  oldtariff.start_date  }}
    .flex.flex-col.w-full.mt-3
        p.text-annotationColor.text-sm.font-SourceSansPro.font-semibold.mb-3 {{ $t('editTarifTranslater.type') }}
        .flex.items-center.justify-between.mb-1
          .relative.w-full
            el-select.w-full( v-model="tariff.tariff.type" )
              el-option( v-for="(item, index) in tariffType" :key="index" :value="item.key" :label="item.value" ) {{ item.value }}
        p.price-info(v-if=" tariff.tariff.type=== 'charge_all_units' ") {{ $t('editTarifTranslater.info') }}
        p.price-info(v-else) {{ $t('editTarifTranslater.info_2') }}
    .w-full.flex.w-full.justify-between.mt-2
      .flex.flex-col
        .flex.mb-2.flex-col
          span.mt-3.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3 {{ $t('editTarifTranslater.periodStart') }}
        .flex.flex-col
          el-date-picker(
            type="datetime"
            v-model='tariff.valid_from',
            :clearable='false',
            format='yyyy-MM-dd HH:mm:ss',
            value-format='yyyy-MM-dd HH:mm:ss',
            :placeholder='$t("reports.not-select")'
          )
      .flex.flex-col.ml-2
        .flex.flex-col
          span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3.mb-2 {{ $t("admintariff.period") + ' (' + $t("admintariff.days") + ')' }}
        .flex.mb-4.flex-col
          .flex.items-center
            el-input.w-56(v-model='tariff.tariff.length', type='number')

    .w-full.flex.w-full.justify-between
      .flex.flex-col
        span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3.mb-2 {{ $t('editTarifTranslater.max') }}
        el-input.w-56(v-model='tariff.tariff.units', type='number')
      .flex.flex-col
        span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold.mt-3.mb-2 {{ $t('editTarifTranslater.amountPer1Object') }} ({{tariff.currency.display_name}})
        el-input.w-56(v-model='tariff.tariff.price', type='number')

    .flex.justify-between.items-center.w-full.mt-3.py-2.px-4(style='background-color:#F6F7FA; border-radius:3px')
      .info-title {{ $t('editTarifTranslater.nextPayment') }}
      .flex.flex-col.w-48
        .info-title {{ tariff.valid_to }}
        .price-info {{ $t('editTarifTranslater.amount') }} {{ tariff.tariff.type=== 'charge_all_units' ? `${tariff.tariff.units * tariff.tariff.price}   `  : '~~~~~' + ` ${tariff.currency.display_name}`}}
    .flex.justify-between.items-center.w-full.mt-3.py-2.px-4(v-if='isTrialStart' style='color:#BD6039 ; background-color:#FFF1E9; border-radius:3px')
      p.info-text {{trialText}}
    .flex.justify-between.items-center.w-full.mt-3.py-2.px-4(v-if='isPreviousDate' style='color:#BD6039; background-color:#FFF1E9; border-radius:3px')
      p.info-text {{ isPreviousDateText }}
    .flex.justify-between.items-center.w-full.mt-3.py-2.px-4(v-if='isPayementChanged' style='color:#20579A ; background-color:#DBEBFF; border-radius:3px')
      p.info-text {{isPayementChangedText}}
    .flex.justify-between.items-center.w-full.mt-3.py-2.px-4(v-if='isPreviousAlert' style='color:#B62424 ; background-color:#FFDBDB; border-radius:3px')
      p.info-text {{ $t('editTarifTranslater.dateNextPayment') }}


  .flex.mt-6.absolute.col-start-1.col-end-3.justify-center.items-center(style='bottom:20px;border-top:1px solid #CAD6DF; width: 100%; height: 64px')
    p.cursor-pointer.cancelBtn.btn.mr-8(

      @click='$emit("close")',
      Cancel
    ) {{ $t("admintariff.cancel") }}
    p.btn.btnSave.cursor-pointer(@click='changeTariff') {{ $t("admintariff.accept") }}
  historyChange(
      v-if='isHistory',
      @close='isHistory = false',
      :companyId='tariff.company_id'
    )
</template>
<script>
import { mapGetters } from 'vuex'
import { billingApi } from '@/api'
import moment from 'moment'

export default {
  components: {
    historyChange: () => import('./historyChange.vue')
  },
  props: ['currenttariff'],
  data() {
    return {
      oldtariff: {},
      isTrialStart: false,
      isPreviousAlert: false,
      isPreviousDate: false,
      isPayementChanged: false,
      isPayementChangedText: '',
      trialText: '',
      isPreviousDateText: '',
      tariff: {
        type: '',
        amount: '',
        company_id: '',
        company_status: '',
        currency: '',
        tariff: {
          length: '',
          price: '',
          total: '',
          units: ''
        },
        start_date: '',
        valid_from: '',
        valid_to: ''
      },
      companyDetails: '',
      isHistory: false
    }
  },
  computed: {
    ...mapGetters({
      tariffType: 'properties/tariffType'
    })
  },
  watch: {
    'tariff.valid_from': function (val) {
      const today = moment()
      const givenDate = moment(val, 'YYYY-MM-DD HH:mm:ss')
      const currentDate = moment(val).format('YYYY-MM-DD')

      this.tariff.valid_to = moment(currentDate)
        .add(this.tariff.tariff.length, 'days')
        .format('YYYY-MM-DD HH:mm:ss')

      const currentTarifDate = moment(this.tariff.valid_from).format(
        'YYYY-MM-DD'
      )
      this.isPayementChanged = true
      this.isPreviousDate = false
      this.isPayementChangedText = `${this.$t(
        'editTarifTranslater.dateNextPayment'
      )} ${moment(this.oldtariff.valid_to)
        .add(this.oldtariff.tariff.length, 'days')
        .format('YYYY-MM-DD ')} на ${moment(this.tariff.valid_to).format(
        'YYYY-MM-DD '
      )}.`
      if (givenDate.isAfter(today)) {
        this.trialText = `${this.$t(
          'editTarifTranslater.setStartTarif'
        )} ${moment(today).format('YYYY-MM-DD')} по ${currentTarifDate} .`
        this.isTrialStart = true
      } else if (givenDate.isBefore(today)) {
        this.isTrialStart = false
        const nextPaymentDate = moment(this.oldtariff.valid_to).format(
          'YYYY-MM-DD'
        )
        console.log(nextPaymentDate)
        this.isPreviousDate = true
        this.isPreviousDateText = `${this.$t(
          'editTarifTranslater.setBeforeDate'
        )}  ${nextPaymentDate} на ${moment(currentDate)
          .add(this.tariff.tariff.length, 'days')
          .format('YYYY-MM-DD')} .`
      }
      // check current date - test
      const isCurrentDate = moment(today).format('YYYY-MM-DD') === currentDate
      if (isCurrentDate) {
        this.isPayementChanged = false
        this.isPreviousDate = false
      }
    }
  },
  methods: {
    showHistory() {
      this.isHistory = true
    },
    changeTariff() {
      const today = moment()
      const period = this.tariff.tariff.length
      const nextPaymentDate = moment(this.tariff.valid_from)
      const nextPaymentDatePlusPeriod = moment(nextPaymentDate).add(
        period,
        'days'
      )

      if (nextPaymentDatePlusPeriod.isBefore(today)) {
        this.isPreviousAlert = true
      } else {
        this.isPreviousAlert = false

        const formData = this.tariff
        delete formData.companyDetails
        if (this.tariff.company_status === 'BLOCKED') {
          formData.company_status = 'ACTIVE'
        }

        billingApi.tariffUpdate(
          formData,
          (response) => {
            const formdata = {
              ...response.data,
              companyDetails: this.companyDetails
            }
            this.$emit('updatetariff', formdata)
          },
          (err) => console.log('err billingApi', err)
        )
      }
    }
  },
  mounted() {
    this.tariff = this.currenttariff
    this.companyDetails = this.currenttariff.companyDetails
    this.oldtariff = JSON.parse(JSON.stringify(this.currenttariff))
  }
}
</script>
<style scoped>
.price-info {
  width: 70%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #a6aeb8;
}
.info-title {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #253545;
  text-align: left;
}
.info-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  text-align: left;
}
.btn {
  width: 197px;
  padding: 8px 0px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;

  text-align: center;

  border-radius: 6px;
}
.cancelBtn {
  color: #fff;

  background: #c8d6df;
}
.btnSave {
  color: #fff;
  background: #446c9d;
}
</style>
